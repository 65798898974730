@media (max-width: 859px) {
  .instruction-section {
    .callout, .nelvin-small {
      display: none;
    }
  }
}

.instruction-section {
  position: relative;
  --glow: rgba(64, 224, 255, 1);
  
  .callout {
    margin-top: -10px;
    margin-left: 0.5em;
    position: absolute;
    // filter: drop-shadow(0 0 2px var(--glow));
  }
  .nelvin-small {
    position: absolute;
    filter: drop-shadow(0 0 2px var(--glow));
  }
}

.nelvin-small {
  margin-right: 0.3em;
  width: 88px;
  transition: 0.06s ease;
}

.nelvin {
  margin-right: 0.6em;
  width: 176px;
  transition: 0.06s ease;
}

.figure {
  margin-top: 1em;
  width: 740px;
}

.nelvin:active, .nelvin-small:active {
  transform: rotate(-20deg);
}

.game-text {
  font-family: Garamond, serif;
  @media (prefers-color-scheme: light) {
    color: #704F16;
  }
}
  
.item-number {
  position: absolute;
  font-size: 0.6em;
  top: 3px;
  left: 1.75em;
  // background-color: var(--solarized-mono3);
  background-color: rgba(255, 255, 255, 0.875);
  @media (prefers-color-scheme: dark) {
    background-color: rgba(0, 0, 0, 0.875);
  }
  // border-radius: 0.3em;
  border-radius: 2em;
  padding: 0 0.4em;
  text-align: center;
  border: 1px solid var(--solarized-mono1);
  border-top: 0;
}

.callout {
  position: relative;
}

.callout:has(> .item-number) {
  padding: 1em;
}

.nelvin ~ .callout, .nelvin-small + .callout, .instruction-section .callout {
  background-color: rgba(255, 255, 255, 0.95);
  @media (prefers-color-scheme: dark) {
    background-color: rgba(0, 0, 0, 0.95);
  }
  border-radius: 2em;
  font-family: 'Comic Neue', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  .item-number {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif;
  }
}

.toshe-square {
  @media (prefers-color-scheme: light) {
    color: #d31524;
  }
}

.hp {
  color: #1fc000;
}

.ep {
  color: #e39200;
}

.xp {
  color: #31b4bf;
}
