.class-widget {
  display: inline-block;
  width: 192px;
  background: var(--solarized-mono2);
  border: 1px solid var(--solarized-mono1);
  border-radius: 3em 3em 1em 1em;
  padding: 1em;
  margin: 0.5em;
}

.class-widget details > summary {
  cursor: pointer;
  list-style: none;
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 1em;
  align-items: center;
}

.stats-grid label {
  grid-column: 1;
}

.stats-grid meter {
  width: 100%;
}

.stats-grid meter.damage-bar::-webkit-meter-optimum-value {
  background-color: orange;
}
.stats-grid meter.resilience-bar::-webkit-meter-optimum-value {
  background-color: blue;
}
.stats-grid meter.versatility-bar::-webkit-meter-optimum-value {
  background-color: green;
}
.stats-grid meter.difficulty-bar::-webkit-meter-optimum-value {
  background-color: black;
}
