@keyframes expand {
  from {
    opacity: 0;
    line-height: 0;
  }
}
details[open] > :not(summary) {
  animation: expand 0.3s;
}

.search-button {
  cursor: pointer;
}
#header-search {
  animation: expand 0.3s;
  display: none;
  line-height: normal;
  margin-bottom: 1em;
}
#collapsible-search {
  display: none;
}
@media screen and (min-width: 600px) {
  .site-nav .page-link:not(:last-child) {
    margin-right: 14px;
  }
  #collapsible-search {
    display: inline;
  }
}

.subsite-title {
  text-transform: uppercase;
  font-size: .6em;
  vertical-align: top;
}

hr {
  border: none;
  border-bottom: 1px solid var(--solarized-mix1);
}

table ul {
  margin-bottom: 0;
}

.record-name {
  font-style: italic;
  overflow-wrap: normal;
}

.bar {
  margin-top: 1em;
  width: 100%;
  background-color: var(--solarized-mono2);
  border-radius: 0.5em;
}
  
.bar-fill {
  display: block;
  height: 0.5em;
  background-color: var(--solarized-mono00);
  border-radius: 0.5em;
}

.small-text {
  text-transform: uppercase;
  font-size: .6em;
  vertical-align: top;
  white-space: nowrap;
}

.quiet-text {
  color: var(--solarized-mono1);
}

.enemy-level {
  float: right;
  border: ridge #7bb4b9 2px;
  width: 2em;
  line-height: 2em;
  text-align: center;
  margin-bottom: 0.5em;
}

.enemy-image {
  display: block;
  max-width: 90%;
  margin: auto;
  border: 4px ridge;
}

li.user-stat-changes {
  list-style-type: '☉';
  border-left: 1px solid var(--solarized-mono1);
  border-radius: 0.5em;
  padding-left: 0.5em;
  margin-left: -0.5em;
}

li.opponent-stat-changes {
  list-style-type: '☍';
  border-left: 1px solid var(--solarized-mono1);
  border-radius: 0.5em;
  padding-left: 0.5em;
  margin-left: -0.5em;
}

.compendium-link > a::after {
  content: '📖';
  font-size: small;
  margin-left: 0.125em;
}

.internal-link > a::after {
  content: '🔗';
  font-size: small;
  margin-left: 0.125em;
}

.game-info {
  font-style: italic;  
}

.earth, .water, .fire, .poison, .ice, .lightning, .frostfire, .elemental, .physical {
  padding: 0 0.3em;
  border-radius: 0.3em;
  white-space: nowrap;
  &.record-name {
    border-radius: 0.8em 0.3em;    
  }
}

.earth {
  background-color: #b5e080;
}

.water {
  background-color: #a7d3e8;
}

.fire {
  background-color: #e6ba90;
}

.poison {
  background-color: #befa46;
}

.ice {
  background-color: #c0f0f0;
}

.lightning {
  background-color: #f2f2aa;
}

.frostfire {
  background: linear-gradient(to right, #c0f0f0, #e6ba90);
}

.elemental {
  background: linear-gradient(#b5e080, #a7d3e8, #e6ba90);
}

.physical {
  background: linear-gradient(rgba(128, 128, 128, 0.3), rgba(128, 128, 128, 0));
}

@media (prefers-color-scheme: dark) {
  .earth {
    background-color: #2c4210;
  }

  .water {
    background-color: #1f4758;
  }

  .fire {
    background-color: #4e3822;
  }

  .poison {
    background-color: #21501c;
  }

  .ice {
    background-color: #1a5a5a;
  }

  .lightning {
    background-color: #44440a;
  }

  .frostfire {
    background: linear-gradient(to right, #1a5a5a, #4e3822);
  }

  .elemental {
    background: linear-gradient(#2c4210, #1f4758, #4e3822);
  }

  .physical {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
  }  
}
