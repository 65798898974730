.site-nav {
  z-index: 1;
}

@media (max-width: 819px) {
  .large-screens-only {
    display: none !important;
  }
}
@media (min-width: 820px) {
  .small-screens-only {
    display: none !important;
  }
}

.site-header.decorative-background {
  background: url("/assets/img/background-light.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  @media (prefers-color-scheme: dark) {
    background: url("/assets/img/background-dark.jpg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.decorative-background .site-nav .page-link, .decorative-background .site-title {
  text-shadow: 0 0 4px rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    text-shadow: 0 0 4px rgba(255, 255, 255, 0.2);
  }
}

.decorative-background .site-title img {
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 1));
  @media (prefers-color-scheme: dark) {
    filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.2));
  }
}

.callout {
  display: inline-block;
  padding: 0.6em 1em;
  background: var(--solarized-mono2);
  border: 1px solid var(--solarized-mono1);
  border-radius: 0.3em;
  > p, > ul {
    margin-bottom: 0;
  }
}

.emoji {
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji";
}

path.monocolour {
  fill: var(--solarized-mono01);
}

.decorative-circle {
  border-radius: 100%;
  border: 4px ridge;
  border-top: none;
  border-left: none;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-1em);
  }
}
:target {
  animation: slide-in 0.5s;
}

.pixel-art {
  image-rendering: pixelated;
}

ul {
  list-style-type: "❧ ";
}
    
ul.horizontal li {
  display: inline-block;
  
  + li:before {
    content: " ❧";
  }
}

.hover-highlight:hover {
  filter: brightness(107%) contrast(107%);
}
